// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-namnlappar-billiga-namnlappar-js": () => import("./../../../src/pages/namnlappar/billiga-namnlappar.js" /* webpackChunkName: "component---src-pages-namnlappar-billiga-namnlappar-js" */),
  "component---src-templates-main-category-template-js": () => import("./../../../src/templates/mainCategoryTemplate.js" /* webpackChunkName: "component---src-templates-main-category-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/productTemplate.js" /* webpackChunkName: "component---src-templates-product-template-js" */)
}

